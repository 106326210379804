export const config = {
    api_base: 'https://prod.diningtek.com/api/v1',
    api_root : 'https://prod.diningtek.com',
    logo_img_root : '/img/delcarmenwestmont-logo.png',
    banner_img_root : '/img/delcarmenwestmont-banner.jpg',
    resid : '',
    key_value : 'delcarmenwestmont',
    secret_value : 'delcarmenwestmont',
    iframe_root : 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2974.1569859939477!2d-87.97168008456123!3d41.80337847922826!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x880e4e8b3c827247%3A0xe93cd787ea3c82b2!2sDel%20Carmen%20Mexican%20Restaurant!5e0!3m2!1sen!2sin!4v1631773458359!5m2!1sen!2sin',
    facebook_link:'#',
    RestaurantName:"Del Carmen Westmont"
};
